






























































































































































































































































































import {Component, Emit, Vue, Watch} from "vue-property-decorator";
import StruttureService, {Struttura, StruttureResponse} from "../services/StruttureService"
import PrenotazioneService, {Prenotazione} from "../services/PrenotazioneService"
import CampiService from "@/services/CampiService";
import {DateTime} from "luxon";
import {Filtro} from "@/Classes/FiltroClass";
import {Evento} from "@/Classes/EventoClass";
import AggiungiPrenotazioneComponent from "@/components/aggiungiPrenotazioneComponent.vue";
import ScontiService, {ScontiRequest} from "@/services/ScontiService";
import {PrenotazioneModel} from "@/Classes/PrenotazioneClass";
import {PrenotaModel} from "@/Classes/PrenotaClass";
import SpostamentoPrenotazioneComponent from "@/components/spostamentoPrenotazioneComponent.vue";
import AggiungiPrenotazioneMultipla from "@/components/aggiungiPrenotazioneMultipla.vue";
import PaymentComponent from "@/components/PaymentComponent.vue";

export type FormAnnulla = {
  commentoAnnulla: string
}
@Component({
  components: {PaymentComponent, SpostamentoPrenotazioneComponent, AggiungiPrenotazioneComponent, AggiungiPrenotazioneMultipla}
})
export default class Home extends Vue {

  public prenotazioneDaSpostare: PrenotaModel = new PrenotaModel();
  public dialogSpostaVisible:boolean = false;
  public dialogAddVisible: boolean = false;
  public dialogVisible: boolean = false;
  public dialogMultiVisible: boolean = false
  public dialogNuovaPrenotazioneMultipla: boolean = false
  public getDay: any = DateTime.now().toJSDate();
  public filtro = new Filtro();
  public showDate: any = new Date()
  validation = {
    campo: [{required: false, message: " "}],
    id_struttura: [{required: true, message: "Campo obbligatorio"}],
    data: [{required: false, message: "Campo obbligatorio"}]
  };

  public dialogPagaVisible:boolean = false;
  public eventoSelezionato: Evento = new Evento();
  public listaStrutture: StruttureResponse = [];
  public listaCampi: any = [];
  public listaPrenotazioniMultiple: PrenotazioneModel[]= []
  public listaPrenotazioniMultipleVisibili: PrenotazioneModel[]= []
  public mostraAttive: boolean = true
  public events: any = [];
  public dataDialogSelected: any = {};
  public dialogAnnullaVisible: boolean = false
  public formAnnulla: FormAnnulla = {
    commentoAnnulla : ""
  }
  public dialogConfig = {
    title: 'Custom dialog title',
    createButtonLabel: 'Save event thingy',
    create: "",
    enableTimeInputs: false,
    fields: [
      {
        name: 'name',
        label: 'Event name'
      },
      {
        name: 'email',
        type: 'email',
        required: true,
        label: 'E-mail',
        value: 'email@example.com'
      },
      {
        label: 'Grouped',
        fields: [
          {
            name: 'Field 1'
          },
          {
            name: 'Field 2'
          }
        ]
      },
      {
        name: 'comments',
        type: 'textarea',
        label: 'comments'
      }
    ]
  }
  public datiStruttura:Struttura = {
    id: 0,
    nome: "",
    descrizione: "",
    sito_web: "",
    telefono: "",
    email:"",
    orario_apertura:  [],
    orario_chiusura:  [],
    annullabile: false,
    rimborsabile:false,
    ore_per_annullamento: 0,
    commento_obbligatorio:false,
    media:  [],
    comune:"",
    istat_comune: "",
    provincia: "",
    istat_provincia:"",
    servizi_di_cortesia:[],
    indirizzo: "",
    logo: "",
    aperto: true,
    posizione:{
      type:"",
      coordinates:[]
    },
    id_subscription: "",
    subscription_price: 0,
    subscription_period_end:""
  }
  regole = {
    commentoAnnulla:[{required: this.datiStruttura.commento_obbligatorio, message: 'Commento necessaria per proseguire'}]
  }
  //mounted
  async mounted() {
    this.events = [];
    try {
      this.getDay = DateTime.now().toJSDate();
      const response = await StruttureService.getStrutture();
      if (response.error) {
        this.$message.error(response.msg);
      } else {

        this.listaStrutture = response.data;
        if (this.listaStrutture.length > 0) {
          this.filtro.id_struttura = this.listaStrutture[0].struttura.value
          await this.ricercaPrenotazioni();
        }
      }
      const response1 = await StruttureService.getStruttura(this.filtro.id_struttura)
      this.datiStruttura = response1.data[0].struttura.info
      this.regole.commentoAnnulla[0].required = this.datiStruttura.commento_obbligatorio
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }

  setShowDate(d: Date) {
    this.showDate = d;
  }

  AnnotaPagamento(){
    this.dialogPagaVisible = !this.dialogPagaVisible
  }

  async SpostamentoPrenotazione(evento: Evento)
  {
    this.prenotazioneDaSpostare.id_sport = evento.customAttribute.campi_sport.sport.id
    this.prenotazioneDaSpostare.id_campo = evento.customAttribute.campi_sport.campo.id
    this.prenotazioneDaSpostare.id_struttura = this.filtro.id_struttura
    this.prenotazioneDaSpostare.id_utente = evento.customAttribute.utente ? evento.customAttribute.utente.id : null
    this.prenotazioneDaSpostare.num_giocatori = evento.customAttribute.numero_giocatori
    this.prenotazioneDaSpostare.start = evento.customAttribute.data_ora
    this.prenotazioneDaSpostare.commento = `(${evento.customAttribute.commento})(${evento.customAttribute.nota_utente}) - SPOSTATA, pren prec:  ${evento.customAttribute.id} del ${DateTime.fromISO(evento.customAttribute.data_ora).toFormat("dd/MM/yyyy")}`
    this.prenotazioneDaSpostare.servizi = evento.customAttribute.servizi
    this.prenotazioneDaSpostare.pagato = evento.customAttribute.pagato
    this.prenotazioneDaSpostare.metodo_pagamento = evento.customAttribute.metodo_pagamento
    this.prenotazioneDaSpostare.promozione_selezionata = evento.customAttribute.promozione ? evento.customAttribute.promozione.id : null
    this.prenotazioneDaSpostare.sconto = evento.customAttribute.sconto ? evento.customAttribute.sconto.id : null
    this.dialogSpostaVisible=!this.dialogSpostaVisible
  }

  async AnnullaPrenotazioneDialog(evento: Evento) {
    this.dialogAnnullaVisible = true
  }

    async AnnullaPrenotazione(evento: Evento) {
      const form = this.$refs.form as any;
      form.validate( async (valid:boolean) => {
        if(valid){
          try{
            this.$confirm('Sei sicuro di voler eliminare la prenotazione?')
                .then( async _ => {
                  evento.customAttribute.commento = this.formAnnulla.commentoAnnulla != "" ? this.formAnnulla.commentoAnnulla : evento.customAttribute.commento
                  const responseAnnullamento = await PrenotazioneService.annullaPrenotazione(evento.customAttribute.id, evento.customAttribute.commento);
                  if (responseAnnullamento.error) {
                    this.$message.error(responseAnnullamento.msg);
                  } else {
                    this.$message.success(responseAnnullamento.data.toString());
                    if (evento.customAttribute.pagato && evento.customAttribute.utente)
                      evento.visibleBuono = false;
                  }
                  this.CloseDialogEvent();
                  this.dialogAnnullaVisible = false
                })
                .catch(_ => {});
          } catch (error){
            this.$message.error(error.message);
          }
        } else {
          this.$message.error('Il campo commento è obbligatorio')
        }
      })


    }

  handleClose(done:any) {
    this.$confirm('Sei sicuro di voler chiudere?')
    .then(_ => {
      done();
      this.CloseDialogEvent();
    })
    .catch(_ => {});
  }
  async EmettiSconto(evento: Evento) {
    if (evento.customAttribute.pagato) {
      const sconto: ScontiRequest = {
        codice_sconto: "RIMBORSO PRENOTAZIONE DEL: " + DateTime.fromJSDate(evento.date).toLocaleString() + " ID: " + evento.customAttribute.id,
        valore_sconto: evento.customAttribute.importo,
        utente: {
          id: evento.customAttribute.utente.id
        },
        tipo_sconto_buono: {
          id: 3
        },
        tipo_sconto: {
          id: 2
        },
        max_eventi: 0,
        start_time: DateTime.now().toISODate(),
        expire_time: DateTime.fromSeconds(2524990179).toISODate(),
        sport: null,
        struttura: {
          id: this.filtro.id_struttura
        }
      }
      const response = await ScontiService.PushSconto(sconto)
      if (response.error) {
        this.$message.error(response.msg)
      } else {
        const res = await PrenotazioneService.RimborsoPrenotazione(evento.customAttribute.id, this.filtro.id_struttura)
        if(res.error){
          this.$message.error(res.msg)
        } else {
          this.$message.success("Sconto creato con successo");
          window.location.reload()
        }
      }
    }
  }

  CloseDialogMultipla() {
    this.riceviPrenotazioniMultiple()
    this.dialogNuovaPrenotazioneMultipla = false
    this.ricercaPrenotazioniMese(this.filtro)
  }

  CloseDialogEvent() {
    this.dialogAddVisible = false;
    this.ricercaPrenotazioniMese(this.filtro)
  }

  CloseDialogPayment(){
    this.dialogVisible = !this.dialogVisible;
    this.dialogPagaVisible = !this.dialogPagaVisible;
    this.ricercaPrenotazioniMese(this.filtro)
  }

   CloseDialogEventSposta() {
    this.dialogSpostaVisible = false;
    this.dialogVisible = false;
    this.ricercaPrenotazioniMese(this.filtro)
  }

  dayClicked(event: any) {
    this.dataDialogSelected = {
      date: event,
      time: "08:00"
    };
    if(DateTime.fromJSDate(event).toISODate() >= DateTime.now().toISODate())
    {
      this.dialogAddVisible = true;
    }
  }

  timeClicked(event: any) {
    if(DateTime.fromJSDate(event.date).toISODate() >= DateTime.now().toISODate())
    {
      this.dataDialogSelected = event;
      this.dialogAddVisible = true;
    }
  }

  eventClicked(event: any) {
    this.eventoSelezionato = event;
    this.dialogVisible = true;
  }

  async openDialogMultiple(){
    await this.riceviPrenotazioniMultiple()
    this.dialogMultiVisible = !this.dialogMultiVisible
  }

  async riceviPrenotazioniMultiple(){
    try{
      const response = await PrenotazioneService.getPrenotazioniMultiple(this.filtro.id_struttura)
      if(response.error) {
        this.$message.error(response.msg)
      } else {
        this.listaPrenotazioniMultiple = response.data
        for(let i = 0; i<this.listaPrenotazioniMultiple.length; i++){
          this.listaPrenotazioniMultiple[i].inizio_periodo= DateTime.fromISO(this.listaPrenotazioniMultiple[i].inizio_periodo).toFormat("dd/MM/yyyy")
          this.listaPrenotazioniMultiple[i].fine_periodo= DateTime.fromISO(this.listaPrenotazioniMultiple[i].fine_periodo).toFormat("dd/MM/yyyy")
          if( DateTime.fromFormat(this.listaPrenotazioniMultiple[i].fine_periodo,"dd/MM/yyyy").toMillis() < DateTime.now().toMillis()){
            this.listaPrenotazioniMultiple[i].scaduta = true
          }
        }
        if(this.mostraAttive){
          for(let i = 0; i<this.listaPrenotazioniMultiple.length; i++){
            if(!this.listaPrenotazioniMultiple[i].annullata && !this.listaPrenotazioniMultiple[i].scaduta){
              this.listaPrenotazioniMultipleVisibili.push(this.listaPrenotazioniMultiple[i])
            }
          }
        }
      }
    } catch (error: any){
      this.$message.error(error.message);
    }
  }

  async cancellaPrenotazioniMultiple(row: PrenotazioneModel){
    try{
      this.$confirm("Fai click su conferama per procedere", "Sei sicuro di voler eliminare le prenotazioni?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(async () => {
        const response = await PrenotazioneService.deletePrenotazioniMultiple(row.id_multipla)
        if(response.error){
          this.$message.error(response.msg)
        } else {
          this.$message.success(response.data)
          await this.riceviPrenotazioniMultiple()
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: "L'elemento non è stato eliminato"
        })
      })

    } catch (error: any){
      this.$message.error(error.message);
    }
  }

  async ricercaPrenotazioni() {
    try {
      const ricercaPrenForm = this.$refs.filtriForm as any;

      ricercaPrenForm.validate(async (valid: boolean) => {
        if (valid) {
          const end = new Date();
          const start = new Date();
          end.setDate(1)
          end.setMonth(new Date().getMonth() + 1)
          start.setDate(1)
          this.filtro.from_date = start;
          this.filtro.to_date = end;
          if (this.filtro.id_campo === "")
            this.filtro.id_campo = null;
          const loading = this.$loading({
            lock: true,
          });
          const responsePrenotazioni = await PrenotazioneService.getPrenotazione(this.filtro);
          if(responsePrenotazioni.error){
            this.$message.error(responsePrenotazioni.msg)
          } else {
            await this.ReformatEventiToCalendar(responsePrenotazioni.data);
            setTimeout(async () => {
              loading.close();
            });
          }
        }
      });
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }

  async ricercaPrenotazioniMese(filtro: Filtro) {
    this.filtro = filtro
    const loading = this.$loading({
      lock: true,
    });
    const responsePrenotazioni = await PrenotazioneService.getPrenotazione(filtro);
    if(responsePrenotazioni.error){
      this.$message.error(responsePrenotazioni.msg)
    } else {
      await this.ReformatEventiToCalendar(responsePrenotazioni.data);
      setTimeout(async () => {
        loading.close();
      });
    }
  }

  async ReformatEventiToCalendar(responsePrenotazioni: PrenotazioneModel[]): Promise<void> {
    const evento = new Evento();
    const arrayEventi: Array<Evento> = [];
    for (let i of responsePrenotazioni) {
      arrayEventi.push(await evento.reformatEvent(i))
    }
    this.events =  arrayEventi;
  }
  @Watch('mostraAttive')
  async modificaMultipleVisibili(){
    this.listaPrenotazioniMultipleVisibili=[]
    if(this.mostraAttive){
      for(let i = 0; i<this.listaPrenotazioniMultiple.length; i++){
        if(!this.listaPrenotazioniMultiple[i].annullata && !this.listaPrenotazioniMultiple[i].scaduta){
          this.listaPrenotazioniMultipleVisibili.push(this.listaPrenotazioniMultiple[i])
        }
      }
    } else {
      for(let i = 0; i<this.listaPrenotazioniMultiple.length; i++){
        if(this.listaPrenotazioniMultiple[i].annullata || this.listaPrenotazioniMultiple[i].scaduta){
          this.listaPrenotazioniMultipleVisibili.push(this.listaPrenotazioniMultiple[i])
        }
      }
    }
  }

  @Watch('filtro.id_struttura')
  async strutturaSelezionataWatch(newVal: number) {
    const responseCampi = await CampiService.ListaCampi(this.filtro.id_struttura);
    this.listaCampi = responseCampi.data.map(x => {
      return {
        label: x.nome,
        value: x.id,
        color: x.color
      }
    }).sort(function(a, b) {
      let nameA = a.label.toUpperCase(); // ignore upper and lowercase
      let nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  //Emit
  @Emit()
  addToCount(n: number) {
  }
}
