import {DateTime} from "luxon";
import {PrenotazioneModel} from "@/Classes/PrenotazioneClass";

export class Evento {
  color:string;
  date: Date;
  startTime: any;
  endTime: any;
  name: string;
  comments: boolean;
  visibleBuono:boolean;
  customAttribute: PrenotazioneModel;

  constructor() {
    this.visibleBuono = true;
    this.color="blue"
    this.date = new Date();
    this.startTime = null;
    this.endTime = null;
    this.name = "";
    this.comments = false;
    this.customAttribute = new PrenotazioneModel();
  }

  public async reformatEvent(eventoFormat: any): Promise<Evento> {
    const evento = new Evento();
    evento.date = DateTime.fromISO(eventoFormat.data_ora.toString(), {zone : "utc"}).toJSDate()
    evento.color = eventoFormat.campi_sport.campo.color;
    evento.startTime = DateTime.fromISO(eventoFormat.data_ora.toString()).toUTC().toFormat("HH:mm")
    evento.endTime = DateTime.fromISO(eventoFormat.data_fine.toString()).toUTC().toFormat("HH:mm")
    evento.comments = eventoFormat.pagato
    evento.name = eventoFormat.utente ? evento.startTime + "-"+ evento.endTime + " " + eventoFormat.utente.cognome+ " " + eventoFormat.utente.nome +( eventoFormat.commento ? "(" + eventoFormat.commento + ")" : "") + (eventoFormat.nota_utente ? "(" + eventoFormat.nota_utente + ")" : "") : evento.startTime + "-"+ evento.endTime + " " + eventoFormat.commento
    evento.customAttribute = eventoFormat
    evento.customAttribute.commento = eventoFormat.commento
    evento.date.setMinutes( evento.date.getMinutes() + evento.date.getTimezoneOffset())
    return evento
  }
}
