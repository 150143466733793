
















































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {Servizi} from "@/Classes/PrenotazioneClass";
import SportService from "@/services/SportService";
import CampiService from "@/services/CampiService";
import {DateTime} from "luxon";
import PrenotazioneService from "@/services/PrenotazioneService";
import UtentiService from "@/services/UtentiService";

export class Form  {
  id_campo: any
  id_sport: any
  id_operatore: any
  id_utente: any
  giorno_settimana: any[]
  start: string
  end: string
  importo: number
  daPagare:boolean
  pagato?: boolean
  commento:string
  id_struttura:any
  durata_prenotazione: number
  servizi: Servizi[]

  constructor() {
    this.id_campo = null;
    this.giorno_settimana = []
    this.id_sport = null;
    this.id_operatore = null;
    this.id_utente = null
    this.id_struttura = null;
    this.start = new Date().toUTCString();
    this.end = new Date().toUTCString();
    this.importo = 0
    this.daPagare = true
    this.pagato = false;
    this.commento = "";
    this.durata_prenotazione = 0
    this.servizi = [];
  }
}
@Component
export default class aggiungiPrenotazioneMultipla extends Vue{
  @Prop() readonly id_struttura: any
  @Prop() readonly listaStrutture: any
  public form: Form = new Form()
  public listaCampi: any = [];
  public listaSport: any = [];
  public date: any = []
  public oraInizio: string = ""
  public utente: boolean = false
  public loading: boolean = false;
  public listaUtenti: any = [];
  public checkDaPagare: boolean = false

  giorniOptions = [
    {"id": 0, "label": "Lunedì"},
    {"id": 1, "label": "Martedì"},
    {"id": 2, "label": "Mercoledì"},
    {"id": 3, "label": "Giovedì"},
    {"id": 4, "label": "Venerdì"},
    {"id": 5, "label": "Sabato"},
    {"id": 6, "label": "Domenica"}
  ];
  giorniSettimana = [false, false, false, false, false, false, false]
  durate_prenotazioni = [30,60,90,120,150,180,210,240,270,300,330,360,390,420]

  rules = {
    id_sport: [{required: true, message: 'Seleziona uno sport'}],
    id_campo:[{required: true, message: 'Seleziona un campo'}],
    //giorniSelezionati: [{ type: 'array', required: true, message: 'Please select at least one activity type', trigger: 'change' }],
    //date: [{required: true, message: 'Selezionare le date'}],
    //startTime: [{required: true, message: 'Scegliere un orario di inizio'}],
    commento: [{required: true, message: 'Inserire un commento', trigger: 'blur'}]
  }

  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }

  @Watch('form.id_sport')
  async riceviCampi(){
    try {
      this.form.id_campo = null
      const responseCampi = await CampiService.ListaCampi(this.id_struttura, this.form.id_sport)
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          info: x.campi_sport.map(z => {
            return {
              importo: z.prezzo,
              aPersona: z.pagamento_persona,
              durata: z.durata_partita,
              label: z.sport.nome,
              value: z.sport.id
            }
          })
        }
      })
      if (this.listaCampi.length == 1) {
        this.form.id_campo = this.listaCampi[0].value
      }

    } catch (error: any) {
      this.$message.error(error.message);

    }
  }

  async ricercaUtenti(filtro: string) {
    try {
      this.loading = true;
      this.listaUtenti = []
      if (filtro.length > 2) {
        const responseUtenti = await UtentiService.getUtenti(filtro.toUpperCase());
        this.loading = false;
        this.listaUtenti = responseUtenti.data.map(x => {
          return {
            label: `${x.cognome} ${x.nome} -  ${x.cellulare}`,
            value: x.id
          }
        })
      }
    } catch (error: any) {
      this.loading = false;
      this.$message.error(error.message);

    }
  }

  async prenota(){
    const form = this.$refs.form as any;

    form.validate(async (valid: boolean) => {
      if(valid){
        let inizio = DateTime.fromJSDate(this.date[0])
        let fine = DateTime.fromJSDate(this.date[1])
        let ora: string = DateTime.fromISO(<string>this.oraInizio).toFormat("H");
        console.log('ora', ora, 'oraInizio', this.oraInizio)
        let minuti: string = DateTime.fromISO(<string>this.oraInizio).toUTC().toFormat("m");
        const dataInizio = DateTime.utc(inizio.year, inizio.month, inizio.day, +ora, +minuti)
        const dataFine = DateTime.utc(fine.year, fine.month, fine.day, +ora, +minuti)
        this.form.start = dataInizio.toUTC().toString()
        this.form.end = dataFine.toUTC().toString()
        console.log(this.form.start, dataInizio)
        for (let i = 0; i < this.form.giorno_settimana.length; i++) {
          this.giorniSettimana[this.form.giorno_settimana[i]] = true
        }
        this.form.giorno_settimana = this.giorniSettimana
        this.form.id_struttura = this.id_struttura
        if (!this.checkDaPagare) {
          const index = this.listaCampi.findIndex((x: any) => x.value == this.form.id_campo)
          console.log("aaaaaaaaasssssss", this.listaCampi[index].info[0].importo)
          this.form.importo = this.listaCampi[index].info[0].importo
        }
        const response = await PrenotazioneService.prenotazioniMultiple(this.form)
        if(response.error){
          this.$message.error(response.msg)
        } else {
          this.$message.success("Prenotazione avventura con successo")
          this.CloseDialog()
        }
      }
    })
  }

  //mounted
  async mounted() {
    try {
      const responseSport = await SportService.ListaSportStruttura(this.id_struttura)
      this.listaSport = responseSport.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          durata: x.minuti,
          giocatori: x.num_giocatori
        }
      })
      if (this.listaSport.length == 1){
        this.form.id_sport = this.listaSport[0].value
      }
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }

  @Emit()
  CloseDialog() {
    this.$emit("CloseDialog")
  }
}
