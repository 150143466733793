






























































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {PrenotaModel} from "@/Classes/PrenotaClass";
import CampiService from "@/services/CampiService";
import {DateTime} from "luxon";
import PrenotazioneService from "@/services/PrenotazioneService";


@Component
export default class dialogPrenota extends Vue {
  @Prop() readonly dataDialogSelected!: any
  @Prop() readonly id_struttura: any
  @Prop() readonly listaStrutture: any
  @Prop() readonly prenotazioneDaSpostare!: PrenotaModel
  @Prop() readonly id_prenotazione!: number
  public oraSelezionata: any = null;
  public disponibilitaSelezionata:any = null;
  public listaDisponibilita: any = []
  public formPrenotazione: PrenotaModel = new PrenotaModel();
  public listaCampi: any = [];
  public listaSport: any = [];
  public startTime: any = null;
  public loading: boolean = false;
  public assente: boolean = false;
  public listaServizi: any = [];
  public listaUtenti: any = [];
  public nominativoDelega: string = "";
  public listaGiocatori: any = [];
  public dataSelezionata: any = {};
  numeroPartecipanti: number = 0;
  public listaMetodi: any = []
  public metodoPagamentoSelezionato: any = null


  async ReformatDate() {
    let data = DateTime.fromJSDate(this.dataSelezionata)
    let ora: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toFormat("H");
    let minuti: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toFormat("m");
    let dataCompleta = DateTime.utc(data.year, data.month, data.day, +ora, +minuti)
    this.formPrenotazione.start = dataCompleta.toString();
    console.log("data completa",dataCompleta)
  }

  async LoadDisponibilita(){
    let dataselected = DateTime.fromJSDate(this.dataSelezionata)
    let data = DateTime.utc(dataselected.year, dataselected.month,dataselected.day, 8,0,0)
    let query = {
      "id_campo": this.formPrenotazione.id_campo,
      "id_sport": this.formPrenotazione.id_sport,
      "giorno":data.toString()
    }
    try {
      console.log("Data", query)
      const responseDisponibilita = await PrenotazioneService.Disponibilita(query);
      if (responseDisponibilita.error){
        this.$message.error(responseDisponibilita.msg)
      }
      else
      {
        this.listaDisponibilita = responseDisponibilita.data.agende[0].intervalli.map(x=>{
          return{
            label:`${ DateTime.fromISO(x.start).toFormat("HH:mm")} ${DateTime.fromISO(x.end).toFormat("HH:mm")}`,
            value: x
          }
        })
        if(this.listaDisponibilita.length===0)
        {
          this.$message.error("Nessuna disponibilità per la data selezionata")
        }
      }

    }catch (error:any)
    {

    }
  }

  async PrenotaMethod() {
    const loading = this.$loading({
      lock: true,
    });
    try {
      console.log(this.formPrenotazione, this.prenotazioneDaSpostare)
      this.formPrenotazione.num_giocatori = this.prenotazioneDaSpostare.num_giocatori
      this.formPrenotazione.metodo_pagamento = this.metodoPagamentoSelezionato
      await this.ReformatDate()
      const responseSpostamento = await PrenotazioneService.Sposta(this.formPrenotazione, this.prenotazioneDaSpostare.id_struttura, this.id_prenotazione)
      if (!responseSpostamento.error) {
        this.$message.success("Spostamento avvenuto con successo");
        loading.close();
        this.CloseDialog()
      } else {
        this.$message.error(responseSpostamento.msg);
        loading.close();
      }
    } catch (error: any) {
      loading.close();
      this.$message.error(error);

    }
  }

  get OraSelezionata() {
    return this.oraSelezionata
  }

  set OraSelezionata(value: any) {
    this.oraSelezionata = DateTime.fromISO(value).toFormat("HH:mm");
  }

  async CheckIdCampo() {
    let flag: boolean = false;
    for (let j of this.listaCampi) {
      if (this.prenotazioneDaSpostare.id_campo == j.value) {
        this.formPrenotazione.id_campo = this.prenotazioneDaSpostare.id_campo
        flag = true
        break
      }
    }
    if (!flag)
      this.formPrenotazione.id_campo = null
  }

  async CheckMetodo(){
    this.listaMetodi.push(
        {
          value: "CONTANTI",
          label: "CONTANTI"
        },
        {
          value: "CARTA_DI_CREDITO",
          label: "CARTA_DI_CREDITO"
        })
    for (let j = 0; j < this.listaMetodi.length; j++)
    {
      if(this.listaMetodi[j].label === this.prenotazioneDaSpostare.metodo_pagamento)
      {
        this.metodoPagamentoSelezionato = this.prenotazioneDaSpostare.metodo_pagamento
      }
    }
  }
  //mounted
  async mounted() {
    try {
      this.formPrenotazione = this.prenotazioneDaSpostare
      this.dataSelezionata = DateTime.fromISO(this.prenotazioneDaSpostare.start).toJSDate()
      this.oraSelezionata = DateTime.fromISO(this.prenotazioneDaSpostare.start).toFormat("HH:mm")
      const responseCampi = await CampiService.ListaCampi(this.prenotazioneDaSpostare.id_struttura, this.prenotazioneDaSpostare.id_sport);
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id
        }
      })
      await this.CheckIdCampo();
      await this.CheckMetodo()
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }


  @Watch('formPrenotazione.id_sport')
  async sportSelectedWatch() {
    /*this.formPrenotazione.num_giocatori = 0;
    let idSport = this.formPrenotazione.id_sport as number;
    const responseServizi = await ServiziService.ListaServizi(this.id_struttura, idSport);
    this.listaServizi = responseServizi.data.map(x => {
      return {
        servizio: x,
        quantita: 0
      }
    })
    const responseSport = await SportService.ListaSportStruttura(this.id_struttura);
    for (let j of responseSport.data)
    {
      if(idSport == j.id)
      {
        this.listaGiocatori =  j.num_giocatori.map(x =>{
          return{
            label: x,
            value: x
          }
        })
      }
    }
    if (this.listaGiocatori.length == 1)
      this.formPrenotazione.num_giocatori = this.listaGiocatori[0].value*/

  }

  @Watch('id_struttura')
  async strutturaSelezionataWatch() {
    try {
      const responseCampi = await CampiService.ListaCampi(this.id_struttura);
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          info: x.campi_sport.map(z => {
            return {
              importo: z.prezzo,
              aPersona: z.pagamento_persona,
              durata: z.durata_partita,
              label: z.sport.nome,
              value: z.sport.id
            }
          })
        }
      })

    } catch (error: any) {
      this.$message.error(error.message);
    }

  }

  @Emit()
  CloseDialog() {
    this.$emit("CloseDialogSposta")
  }
}
