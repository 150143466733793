import BaseService, {APIResponse} from "@/services/BaseService";
import {Payment} from "@/Classes/MetodoPagamento";

export default class PagamentoServices extends BaseService {
  static async Payment(body:Payment): Promise<APIResponse<string>> {
    return await this.perform<string>({
      url: "operatore/pagamento/prenotazione",
      method: "POST",
      body:body
    });
  }
}
