import {Servizi} from "@/Classes/PrenotazioneClass";

export class PrezzoBody  {
  id_campo: any
  id_sport: any
  start: string
  num_giocatori:number
  id_struttura:any
  servizi: Servizi[]

  constructor() {
    this.id_campo = null;
    this.id_sport = null;
    this.id_struttura = null;
    this.start = new Date().toUTCString();
    this.num_giocatori = 0;
    this.servizi = [];
  }
}
