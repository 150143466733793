import BaseService, {APIResponse} from "@/services/BaseService";
import {MetodoPagamento} from "@/Classes/MetodoPagamento";

export default class MetodiPagamentoService extends BaseService {
  static async listaMetodi(id_struttura: number): Promise<APIResponse<Array<MetodoPagamento>>> {
    return await this.perform<Array<MetodoPagamento>>({
      url: "operatore/prenotazioni/metodiPagamento/" + id_struttura,
      method: "GET"
    });
  }
}
