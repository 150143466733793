import {Servizi} from "@/Classes/PrenotazioneClass";

export class PrenotaModel  {
  id_campo: any
  id_sport: any
  id_utente: any
  start: string
  // end: DateTime
  pagato?: boolean
  metodo_pagamento?: string
  prenotazioneWeb: boolean
  commento:string
  num_giocatori:number
  id_struttura:any
  servizi: Servizi[]
  sconto: any
  promozione_selezionata: any
  importo:number

  constructor() {
    this.id_campo = null;
    this.importo = 0;
    this.id_sport = null;
    this.id_utente = null;
    this.id_struttura = null;
    this.start = new Date().toUTCString();
    this.pagato = false;
    this.num_giocatori = 0;
    this.metodo_pagamento = "";
    this.prenotazioneWeb = true
    this.commento = "";
    this.servizi = [];
    this.promozione_selezionata = null
    this.sconto = null
  }
}
