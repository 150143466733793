















































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {Evento} from "@/Classes/EventoClass";
import MetodiPagamentoService from "@/services/MetodiPagamentoService";
import {Payment} from "@/Classes/MetodoPagamento";
import PagamentoServices from "@/services/PagamentoServices";
import Structure from "@/views/Structure.vue";
import StruttureService, {Struttura, StruttureResponse} from "@/services/StruttureService";

@Component
export default class PaymentComponent extends Vue {
  @Prop() eventoSelezionato!: Evento
  @Prop() readonly id_struttura: any
  public listaMetodiPagamento:any = []
  public formPay = new Payment()
  public importo: number = 0
  public datiStruttura:Struttura = {
    id: 0,
    nome: "",
    descrizione: "",
    sito_web: "",
    telefono: "",
    email:"",
    orario_apertura:  [],
    orario_chiusura:  [],
    annullabile: false,
    rimborsabile:false,
    ore_per_annullamento: 0,
    commento_obbligatorio:false,
    media:  [],
    comune:"",
    istat_comune: "",
    provincia: "",
    istat_provincia:"",
    servizi_di_cortesia:[],
    indirizzo: "",
    logo: "",
    aperto: true,
    posizione:{
      type:"",
      coordinates:[]
    },
    id_subscription: "",
    subscription_price: 0,
    subscription_period_end:""
  }

  regole = {
    causale:[{required: this.datiStruttura.commento_obbligatorio, message: 'Causale necessaria per proseguire'}]
  }

  async CheckMetodo(){
    const responseListaMetodi = await MetodiPagamentoService.listaMetodi(this.id_struttura);
    this.listaMetodiPagamento = responseListaMetodi.data
    this.importo = this.eventoSelezionato.customAttribute.importo/100
  }
  async InviaDatiPagamento(){
    const form = this.$refs.formPay as any;

    console.log(form)
    form.validate( async (valid:boolean) => {
      console.log(valid)
      if(valid) {
        const loading = this.$loading({
          lock: true,
        });
        try {
          this.formPay.idStruttura = this.id_struttura
          this.formPay.idPrenotazione = this.eventoSelezionato.customAttribute.id
          this.formPay.importo = this.importo*100;
          this.formPay.importo_sconto_operatore = ((this.eventoSelezionato.customAttribute.importo - this.formPay.importo) < 0) ? 0 : (this.eventoSelezionato.customAttribute.importo - this.formPay.importo)
          const responsePagamento = await PagamentoServices.Payment(this.formPay)
          if(!responsePagamento.error){
            loading.close()
            this.CloseDialog()
          }
          else
          {
            this.$message.error(responsePagamento.msg);
            loading.close();
          }

        }catch (error:any){
          this.$message.error("Qualcosa è andato storto");
          loading.close();
        }
      } else {
        this.$message.error('Il campo causale è obbligatorio')
      }
    })
  }
  async getInfoStruttura(){
    const response = await StruttureService.getStruttura(this.id_struttura)
    this.datiStruttura = response.data[0].struttura.info
    console.log(this.datiStruttura.commento_obbligatorio)
    this.regole.causale[0].required = this.datiStruttura.commento_obbligatorio

  }
  async created(){
    await this.getInfoStruttura()
  }

  mounted(){
    console.log("Prova")
    this.CheckMetodo();
    //this.formPay.importo = this.eventoSelezionato.customAttribute.importo
  }

  @Watch('eventoSelezionato')
  WatchEvento(newVal: Evento){
    console.log(newVal)
    this.importo = newVal.customAttribute.importo/100;
  }
  @Emit()
  CloseDialog() {
    this.$emit("CloseDialogPayment")
  }
}
