export interface Utente {
  email: string;
  id: number;
  cognome: string;
  cellulare: string
}

export interface Servizio {
  nome: string;
  descrizione: string;
  id: number;
}

export interface Servizi {
  quantita: number;
  id_servizio: Servizio;
}

export interface Campo {
  nome: string;
  id: number;
}

export interface Sport {
  nome: string;
  id: number;
  num_giocatori:number
}

export interface CampiSport {
  id: number;
  campo: Campo;
  sport: Sport;
}
export interface Sconto {
  id: number;
  start_time: Date;
  expire_time: Date;
  codice_sconto: string;
  valore_sconto: number;
  count_eventi: number;
  max_eventi: number;
  timestamp: Date;
}

export interface Promozione {
  id: number;
  giorno_settimana: boolean[];
  ora_inizio_promozione: string;
  ora_fine_promozione: string;
  start_time: Date;
  expire_time: Date;
  des_promozione: string;
  valore_promozione: number;
  pagamento_online: boolean;
  timestamp: Date;
}




export class PrenotazioneModel  {
  annullata: boolean
  data_ora: any;
  data_fine: any;
  utente: Utente;
  metodo_pagamento: string;
  numero_giocatori: number;
  id: number;
  pagato: boolean;
  causale_pagamento: string
  commento: string;
  servizi: Servizi[];
  campi_sport: CampiSport;
  import_campo: number
  importo: number;
  importo_sconto: number;
  importo_rimborso: number;
  importo_promozione: number
  importo_sconto_operatore: number
  rimborsato: boolean;
  nota_utente: string;
  sconto: Sconto;
  promozione: Promozione;
  prenotazioneWeb: boolean;
  id_multipla: string
  inizio_periodo: any
  fine_periodo: any
  scaduta: boolean


  constructor() {
    this.annullata = false
    this.data_ora = "";
    this.data_fine = "";
    this.metodo_pagamento="N/D";
    this.commento = ""
    this.import_campo = 0
    this.importo = 0;
    this.importo_rimborso = 0;
    this.importo_sconto = 0;
    this.importo_promozione = 0
    this.importo_sconto_operatore = 0
    this.rimborsato = false;
    this.servizi = []
    this.nota_utente = "";
    this.numero_giocatori = 0;
    this.prenotazioneWeb = false
    this.id_multipla = ""
    this.inizio_periodo = ""
    this.fine_periodo = ""
    this.scaduta = false
    this.sconto = {
      id: 0,
      start_time: new Date(),
      expire_time: new Date(),
      codice_sconto: "",
      valore_sconto: 0,
      count_eventi: 0,
      max_eventi: 0,
      timestamp: new Date()
    }
    this.promozione = {
      id: 0,
      giorno_settimana: [],
      ora_inizio_promozione: "",
      ora_fine_promozione: "",
      start_time: new Date(),
      expire_time: new Date(),
      des_promozione: "",
      valore_promozione: 0,
      pagamento_online: false,
      timestamp: new Date()
    }
    this.utente = {
      id: 0,
      email:"" ,
      cognome : "",
      cellulare:""
    }
    this.id = 0;
    this.pagato = false;
    this.causale_pagamento = ""
    this.campi_sport = {
      campo: {
        nome: "",
        id: 0
      },
      id: 0,
      sport: {
        num_giocatori:0,
        nome: "",
        id: 0
      }
    }
  }
};
