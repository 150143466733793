export class MetodoPagamento{

  id: number
  descrizione:string

  constructor() {
    this.id = 0 ;
    this.descrizione =""
}
}

export class Payment
{
  importo : number
  importo_sconto_operatore: number
  idMetodoPagamento : any
  idPrenotazione: number
  idStruttura: number
  causale: string | null

  constructor() {
    this.importo = 0;
    this.importo_sconto_operatore = 0
    this.idMetodoPagamento = 1;
    this.idPrenotazione = 0;
    this.causale = null;
    this.idStruttura = 0;
  }
}

