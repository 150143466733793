
























































































































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {PrenotaModel} from "@/Classes/PrenotaClass";
import CampiService from "@/services/CampiService";
import ServiziService from "@/services/ServiziService";
import {DateTime} from "luxon";
import UtentiService from "@/services/UtentiService";
import PrenotazioneService, {Disponibilita, Intervallo} from "@/services/PrenotazioneService";
import SportService from "@/services/SportService";
import {PrezzoBody} from "@/Classes/Prezzo";
import MetodiPagamentoService from "@/services/MetodiPagamentoService";

interface IOrari {
  ora: string,
  attivo: boolean
}

@Component
export default class dialogPrenota extends Vue {
  @Prop() readonly id_struttura: any
  @Prop() readonly listaStrutture: any
  public oraSelezionata: any = null;
  public listaMetodi: any = []
  public metodoPagamentoSelezionato: any = null;
  public formPrenotazione: PrenotaModel = new PrenotaModel();
  public listaCampi: any = [];
  public listaSport: any = [];
  public startTime: any = null;
  public loading: boolean = false;
  public assente: boolean = false;
  public listaServizi: any = [];
  public listaUtenti: any = [];
  public contattoDelega: string = "";
  public nominativoDelega: string = "";
  public commentoDelega: string = "";
  public listaGiocatori: any = [];
  public durataPartita: number = 0
  numeroPartecipanti: number = 0;
  public disponibilitaSelezionata: any = null;
  public listaDisponibilita: any = []
  public dataSelezionata: any = "";
  public promozioneString: string = ""


  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }

  async CheckMetodo() {
    const responseListaMetodi = await MetodiPagamentoService.listaMetodi(this.id_struttura);
    this.listaMetodi = responseListaMetodi.data
  }

  public async LoadDisponibilita() {
    let dataselected = DateTime.fromJSDate(this.dataSelezionata)
    let data = DateTime.utc(dataselected.year, dataselected.month, dataselected.day, 8, 0, 0)
    let query = {
      "id_campo": this.formPrenotazione.id_campo,
      "id_sport": this.formPrenotazione.id_sport,
      "giorno": data.toString()
    }
    try {
      const responseDisponibilita = await PrenotazioneService.Disponibilita(query);
      if (responseDisponibilita.error) {
        this.$message.error(responseDisponibilita.msg)
      } else {
        this.listaDisponibilita = responseDisponibilita.data.agende[0].intervalli.map(x => {
          return {
            label: `${DateTime.fromISO(x.start).toUTC().toFormat("HH:mm")} - ${DateTime.fromISO(x.start).plus({minute: this.durataPartita}).toUTC().toFormat("HH:mm")}`,
            value: x
          }
        })
        if (this.listaDisponibilita.length === 0) {
          this.$message.error("Nessuna disponibilità per la data selezionata")
        }
      }

    } catch (error: any) {

    }
  }

  @Watch('formPrenotazione.id_sport')
  public modificaSport() {
    this.dataSelezionata = ""
    this.disponibilitaSelezionata = null
    this.formPrenotazione.id_campo = null
    this.listaDisponibilita = []

  }

  @Watch('formPrenotazione.id_campo')
  public async modificaCampo() {
    if(this.formPrenotazione.id_campo != null)
    {
      this.listaDisponibilita = []
      this.disponibilitaSelezionata = null
      if(this.dataSelezionata != "" && this.dataSelezionata != null) {
        await this.LoadDisponibilita()
      }
    }
  }

  @Watch('dataSelezionata')
  public async modificaData() {
    if (this.dataSelezionata != "")
    {
      this.listaDisponibilita = []
      this.disponibilitaSelezionata = null
      await this.LoadDisponibilita()

    }
  }

  async loadCampi() {
    try {
      const responseCampi = await CampiService.ListaCampi(this.id_struttura, this.formPrenotazione.id_sport)
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          info: x.campi_sport.map(z => {
            return {
              importo: z.prezzo,
              aPersona: z.pagamento_persona,
              durata: z.durata_partita,
              label: z.sport.nome,
              value: z.sport.id
            }
          })
        }
      })
      if (this.listaCampi.length == 1) {
        this.formPrenotazione.id_campo = this.listaCampi[0].value
      }

    } catch (error: any) {
      this.$message.error(error.message);

    }
  }

  async ricercaUtenti(filtro: string) {
    try {
      this.loading = true;
      this.listaUtenti = []
      if (filtro.length > 2) {
        const responseUtenti = await UtentiService.getUtenti(filtro.toUpperCase());
        this.loading = false;
        this.listaUtenti = responseUtenti.data.map(x => {
          return {
            label: `${x.cognome} ${x.nome} -  ${x.cellulare}`,
            value: x.id
          }
        })
      }
    } catch (error: any) {
      this.loading = false;
      this.$message.error(error.message);

    }
  }


  async CalcolaMethod() {
    try {
      let prezzo: PrezzoBody = new PrezzoBody();
      prezzo.id_struttura = this.id_struttura;
      prezzo.id_sport = this.formPrenotazione.id_sport;
      prezzo.id_campo = this.formPrenotazione.id_campo;
      let data = DateTime.fromJSDate(this.dataSelezionata)
      let ora: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toUTC().toFormat("H");
      let minuti: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toUTC().toFormat("m");
      let dataCompleta = DateTime.utc(data.year, data.month, data.day, +ora, +minuti)
      prezzo.start = dataCompleta.toString();
      this.formPrenotazione.servizi = [];
      for (let j of this.listaServizi) {
        this.formPrenotazione.servizi.push({
          quantita: j.quantita,
          id_servizio: j.servizio.id
        })
      }
      prezzo.servizi = this.formPrenotazione.servizi;
      prezzo.num_giocatori = this.formPrenotazione.num_giocatori;
      const responseImporto = await PrenotazioneService.getImporto(prezzo);
      if (!responseImporto.error) {
        this.formPrenotazione.importo = responseImporto.data.importo;
        this.formPrenotazione.promozione_selezionata = null;
        this.promozioneString = ""
        this.formPrenotazione.promozione_selezionata = responseImporto.data.miglior_promozione.promozioni[0].id
        this.promozioneString = responseImporto.data.miglior_promozione.promozioni[0].des_promozione
      } else {
        this.$message.error(responseImporto.msg);
      }
    } catch (error: any) {

    }
  }

  async PrenotaMethod() {
    const loading = this.$loading({
      lock: true,
    });
    try {
      const prenotazioneForm = this.$refs.prenotazioneForm as any;
      prenotazioneForm.validate(async (valid: boolean) => {
        if (valid) {
          this.formPrenotazione.commento = this.formPrenotazione.id_utente == null ? `prenotazione per ${this.nominativoDelega} al contatto ${this.contattoDelega}${this.commentoDelega}` : `${this.commentoDelega}`
          let data = DateTime.fromJSDate(this.dataSelezionata)
          let ora: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toUTC().toFormat("H");
          let minuti: string = DateTime.fromISO(<string>this.disponibilitaSelezionata).toUTC().toFormat("m");
          let dataCompleta = DateTime.utc(data.year, data.month, data.day, +ora, +minuti)
          this.formPrenotazione.start = dataCompleta.toUTC().toString();
          this.formPrenotazione.servizi = [];
          for (let j of this.listaServizi) {
            this.formPrenotazione.servizi.push({
              quantita: j.quantita,
              id_servizio: j.servizio.id
            })
          }
          if (this.formPrenotazione.num_giocatori > 0) {
            this.formPrenotazione.metodo_pagamento = this.metodoPagamentoSelezionato;
            this.formPrenotazione.pagato = false;
            this.formPrenotazione.id_struttura = this.id_struttura
            this.formPrenotazione.prenotazioneWeb = true
            if (this.formPrenotazione.id_utente || this.nominativoDelega) {
              const responsePrenotazione = await PrenotazioneService.Prenota(this.formPrenotazione);
              if (!responsePrenotazione.error) {
                this.$message.success("Prenotazione avvenuta con successo");
                this.CloseDialog();
              } else {
                this.$message.error(responsePrenotazione.msg);
                loading.close();
              }
            } else {
              this.$message.error("Inserire almeno un utente o un delegato");
              loading.close();
            }
          } else {
            this.$message.error("Selezionare il numero di giocatori valido");
            loading.close();
          }

        } else {
          loading.close();

        }
      })


    } catch (error: any) {
      loading.close();
      this.$message.error(error);

    }
  }

  get OraSelezionata() {
    return this.oraSelezionata
  }

  set OraSelezionata(value: any) {
    this.oraSelezionata = DateTime.fromISO(value).toFormat("HH:mm");
  }

  //mounted
  async mounted() {
    try {
      await this.CheckMetodo();
      /*this.oraSelezionata = DateTime.fromISO(this.dataDialogSelected.time).toFormat("HH:mm");
      if (this.oraSelezionata === "Invalid DateTime") {
        this.oraSelezionata = DateTime.fromObject({hour: this.dataDialogSelected.time}).toFormat("HH:mm");
      }
        const responseCampi = await CampiService.ListaCampi(this.id_struttura);
        this.listaCampi = responseCampi.data.map(x => {
          return {
            label: x.nome,
            value: x.id,
            info: x.campi_sport.map(z => {
              return {
                importo: z.prezzo,
                aPersona: z.pagamento_persona,
                durata: z.durata_partita,
                label: z.sport.nome,
                value: z.sport.id
              }
            })
          }
        })*/
      const responseSport = await SportService.ListaSportStruttura(this.id_struttura)
      this.listaSport = responseSport.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          durata: x.minuti,
          giocatori: x.num_giocatori
        }
      })
      if (this.listaSport.length == 1){
        this.formPrenotazione.id_sport = this.listaSport[0].value
      }
      await this.sportSelectedWatch(this.formPrenotazione.id_sport);
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }

  @Watch('oraSelezionata')
  async oraSelezionataWatch(newval: any) {

  }

  /*@Watch('formPrenotazione.id_sport')
  async sportSelezionatoWatch() {
    this.formPrenotazione.id_campo = null
    await this.loadCampi();
  }*/

  //@Watch('formPrenotazione.id_sport')
  async sportSelectedWatch(value: any) {
    if(this.formPrenotazione.id_sport != null && this.formPrenotazione.id_sport != ""){
      console.log(this.formPrenotazione.id_sport)
      this.formPrenotazione.num_giocatori = 0
      let num_giocatori: any[] = [];
      this.listaSport.forEach((sport: any) => {
        if (sport.value == value) {
          num_giocatori = sport.giocatori
          this.durataPartita = sport.durata
        }
      })
      this.formPrenotazione.id_campo = null
      await this.loadCampi();
      this.listaGiocatori = num_giocatori
      const responseServizi = await ServiziService.ListaServizi(this.id_struttura, this.formPrenotazione.id_sport);
      if (responseServizi.error) {
        this.$message.error(responseServizi.msg)
        this.CloseDialog()
        return
      }
      this.listaServizi = responseServizi.data.map(x => {
        return {
          servizio: x,
          quantita: 0
        }
      })
      if (this.listaGiocatori.length >= 1)
        this.formPrenotazione.num_giocatori = this.listaGiocatori[0]
    }
  }

  @Watch('id_struttura')
  async strutturaSelezionataWatch() {
    try {
      /*const responseCampi = await CampiService.ListaCampi(this.id_struttura);
      if(responseCampi.error){
        this.$message.error(responseCampi.msg)
        this.CloseDialog()
        return}
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          info: x.campi_sport.map(z => {
            return {
              importo: z.prezzo,
              aPersona: z.pagamento_persona,
              durata: z.durata_partita,
              label: z.sport.nome,
              value: z.sport.id
            }
          })
        }
      })*/

    } catch (error: any) {
      this.$message.error(error.message);
    }

  }

  @Emit()
  CloseDialog() {
    this.$emit("CloseDialog")
  }
}
